import React from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ListItemText } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        span: {
            top: '50%',
            margin: '0 1rem',
            fontWeight: 700,
            cursor: 'pointer',
            position: 'relative',
            height: 'fit-content',
            display: 'inline-block',
            transform: 'translateY(-50%)',
        },
        section: {
            display: 'flex',
            justifyContent: 'space-evenly',
        },
    })
);

function Header({ mobile = false }: { mobile: boolean }) {
    const classes = useStyles();

    if (
        !mobile &&
        (window.visualViewport
            ? window.visualViewport.width >= 1440
            : (document
                  ?.getElementById('dummyDivForScreenSize')
                  ?.getBoundingClientRect().left ?? window.innerWidth) >= 1440)
    ) {
        return (
            <AppBar
                elevation={0}
                position="static"
                style={{
                    height: '64px',
                    color: 'black',
                    backdropFilter: 'blur(5px)',
                    backgroundColor: 'rgba(255,255,255, 0.5)',
                }}
            >
                <Toolbar variant="dense" style={{ height: '64px' }}>
                    <Grid container justify="space-evenly">
                        <Grid
                            item
                            xs={2}
                            className={classes.section}
                            style={{
                                justifyContent: 'flex-start',
                            }}
                        >
                            <img
                                alt="logo"
                                src="assets/brandassets/snibble_logo.svg"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            className={classes.section}
                            style={{ justifyContent: 'flex-start' }}
                        >
                            <Typography
                                variant="subtitle2"
                                className={classes.span}
                                onClick={() => {
                                    const t = document.getElementById(
                                        'advertising'
                                    );
                                    if (t) {
                                        t.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                    }
                                }}
                            >
                                Advertising
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className={classes.span}
                                onClick={() => {
                                    const t = document.getElementById(
                                        'contentPartners'
                                    );
                                    if (t) {
                                        t.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                    }
                                }}
                            >
                                Content Partners
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className={classes.span}
                                onClick={() => {
                                    const t = document.getElementById(
                                        'propaganda'
                                    );
                                    if (t) {
                                        t.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                    }
                                }}
                            >
                                Propaganda
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                onClick={() => {
                                    const t = document.getElementById(
                                        'contactUs'
                                    );
                                    if (t) {
                                        t.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                    }
                                }}
                                className={classes.span}
                            >
                                Contact
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            className={classes.section}
                            style={{
                                marginTop: '0.25rem',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <a href="https://play.google.com/store/apps/details?id=com.snibblecorp.snibble">
                                <img
                                    alt="google play"
                                    style={{
                                        maxHeight: '45px',
                                        marginRight: '1rem',
                                    }}
                                    src="assets/download_play_store.svg"
                                />
                            </a>
                            <a href="https://apps.apple.com/ca/app/snibble/id1464594506">
                                <img
                                    alt="app store"
                                    style={{
                                        maxHeight: '45px',
                                        marginLeft: '1rem',
                                    }}
                                    src="assets/download_app_store.svg"
                                />
                            </a>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    } else {
        return (
            <AppBar
                position="static"
                style={{
                    maxHeight: '64px',
                    color: 'black',
                    backdropFilter: 'blur(5px)',
                    backgroundColor: 'rgba(255,255,255, 0.5)',
                }}
            >
                <Accordion>
                    <AccordionSummary
                        expandIcon={<MenuRoundedIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid
                            item
                            xs={12}
                            style={{ 
                                display: 'flex', width: '100%' }}
                        >
                            <img
                                alt="logo"
                                src="assets/brandassets/snibble_logo.svg"
                            />

                            <span style={{ flexGrow: 1 }} />

                            <Grid
                                item
                                sm={5}
                                md={4}
                                lg={3}
                                className={`store-buttons ${classes.section}`}
                                style={{
                                    marginTop: '0.25rem',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <a href="https://play.google.com/store/apps/details?id=com.snibblecorp.snibble">
                                    <img
                                        alt="google play"
                                        style={{
                                            maxHeight: '45px',
                                        }}
                                        src="assets/download_play_store.svg"
                                    />
                                </a>
                                <a href="https://apps.apple.com/ca/app/snibble/id1464594506">
                                    <img
                                        alt="app store"
                                        style={{
                                            maxHeight: '45px',
                                        }}
                                        src="assets/download_app_store.svg"
                                    />
                                </a>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                        <List style={{ margin: 0, padding: 0, width: '100%' }}>
                            <ListItem
                                button
                                style={{ width: '100%', textAlign: 'center' }}
                            >
                                <ListItemText>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ fontWeight: 700 }}
                                        onClick={() => {
                                            const t = document.getElementById(
                                                'advertising'
                                            );
                                            if (t) {
                                                t.scrollIntoView({
                                                    behavior: 'smooth',
                                                });
                                            }
                                        }}
                                    >
                                        Advertising
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem
                                button
                                style={{ width: '100%', textAlign: 'center' }}
                            >
                                <ListItemText>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ fontWeight: 700 }}
                                        onClick={() => {
                                            const t = document.getElementById(
                                                'contentPartners'
                                            );
                                            if (t) {
                                                t.scrollIntoView({
                                                    behavior: 'smooth',
                                                });
                                            }
                                        }}
                                    >
                                        Content Partners
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            {/* <ListItem
                                button
                                style={{ width: '100%', textAlign: 'center' }}
                            >
                                <ListItemText>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ fontWeight: 700 }}
                                        onClick={() => {
                                            const t = document.getElementById(
                                                'Real Time'
                                            );
                                            if (t) {
                                                t.scrollIntoView({behavior: 'smooth'})
                                            }
                                        }}
                                    >
                                        Real Time
                                    </Typography>
                                </ListItemText>
                            </ListItem> */}
                            <ListItem
                                button
                                style={{ width: '100%', textAlign: 'center' }}
                            >
                                <ListItemText>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ fontWeight: 700 }}
                                        onClick={() => {
                                            const t = document.getElementById(
                                                'propaganda'
                                            );
                                            if (t) {
                                                t.scrollIntoView({
                                                    behavior: 'smooth',
                                                });
                                            }
                                        }}
                                    >
                                        Propaganda
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem
                                button
                                style={{ width: '100%', textAlign: 'center' }}
                            >
                                <ListItemText>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ fontWeight: 700 }}
                                        onClick={() => {
                                            const t = document.getElementById(
                                                'contactUs'
                                            );
                                            if (t) {
                                                t.scrollIntoView({
                                                    behavior: 'smooth',
                                                });
                                            }
                                        }}
                                    >
                                        Contact
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
            </AppBar>
        );
    }
}

export default Header;
