import './App.css';
import Mobile from './components/mobile';
import Desktop from './components/desktop';
import { CssBaseline } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        text: { primary: '#18191F' },
        primary: { main: '#FF08C5', light: '#FF08C5', dark: '#FF08C5' },
    },
    typography: {
        fontFamily: 'proxima-nova, sans-serif',
    },
});

function App() {
    let mobile: boolean = false;
    const [articles, setArticles] = useState<Array<any> | null>(null);

    if (window.screen.width < 481) {
        mobile = true;
    }

    useEffect(() => {
        if (!articles) {
            fetch('https://warroom.snibblecorp.com/v1/articles')
                .then((res) => res.json())
                .then((res) => {
                    setArticles(
                        res.sort((a: any, b: any) => a.order - b.order)
                    );
                });
        }
    }, [articles]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {!mobile ? (
                <Desktop articles={articles ?? []} />
            ) : (
                <Mobile articles={articles ?? []} />
            )}
        </ThemeProvider>
    );
}

export default App;
