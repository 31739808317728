import '../App.css';
import Header from './header';
import Recaptcha from './recaptcha';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { IconButton, withStyles } from '@material-ui/core';
import { TextField as MTextField } from '@material-ui/core';
import React, {
    useState,
    useEffect,
    useRef,
    SetStateAction,
    Dispatch,
} from 'react';

const TextField = withStyles({
    root: {
        '& fieldset': {
            border: 'none',
        },
    },
})(MTextField);

const CARD_WIDTH = 300;

function Desktop({ articles }: { articles: Array<any> }) {
    const videoRef = useRef(null);
    const scrollRef = useRef(null);
    const videoDivRef = useRef(null);
    const explosionRef = useRef(null);
    const sectionOneRef = useRef(null);
    const sectionTwoRef = useRef(null);

    const [name, setName]: [
        string,
        Dispatch<SetStateAction<string>>
    ] = useState('');
    const [email, setEmail]: [
        string,
        Dispatch<SetStateAction<string>>
    ] = useState('');
    const [message, setMessage]: [
        string,
        Dispatch<SetStateAction<string>>
    ] = useState('');

    const [offset, setOffset]: [
        number,
        Dispatch<SetStateAction<number>>
    ] = useState(0);

    const [scrollY, setScrollY]: [
        number,
        Dispatch<SetStateAction<number>>
    ] = useState(0);

    const [newsOffset, setnewsOffset]: [
        number,
        Dispatch<SetStateAction<number>>
    ] = useState(0);

    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [secondLimit, setSecondLimit] = useState(0);
    const [sent, setSent]: [string | null, any] = useState(null);
    const [offsetLimit, setOffsetLimit]: [number | null, any] = useState(null);

    useEffect(() => {
        (scrollRef?.current as any).addEventListener('scroll', () => {
            setScrollY((scrollRef?.current as any).scrollTop);

            if (
                (videoDivRef?.current as any)?.getBoundingClientRect().top <= 0
            ) {
                (videoRef?.current as any)?.play();
            } else if (
                (videoDivRef?.current as any)?.getBoundingClientRect().top >=
                    0 ||
                (videoDivRef?.current as any)?.getBoundingClientRect().bottom -
                    200 <=
                    0
            ) {
                (videoRef?.current as any)?.pause?.();
            }
        });

        setOffset((explosionRef?.current as any)?.getBoundingClientRect().top);
        setOffsetLimit(
            (sectionOneRef?.current as any)?.getBoundingClientRect().bottom -
                350
        );
        setSecondLimit(
            (sectionTwoRef?.current as any)?.getBoundingClientRect().top - 500
        );
    }, []);

    async function handleSend() {
        try {
            if (sent) {
                return;
            }

            setLoading(true);

            await fetch(
                'https://ipwmovx2ze.execute-api.us-east-1.amazonaws.com/default/ContactForm',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ name, email, content: message }),
                }
            );

            setSent('Success');
            setLoading(false);
        } catch (e) {
            setSent('error');
            setLoading(false);
        }
    }

    return (
        <Grid
            container
            justify="center"
            style={{
                maxWidth: '100%',
                height: '100%',
                overflow: 'auto',
                backgroundColor: `rgba(255, 255, 255, ${
                    1 - scrollY / (offsetLimit ?? 1) < 0 &&
                    scrollY > secondLimit
                        ? Math.abs(1 - scrollY / (secondLimit ?? 1)) * 4
                        : 1 - scrollY / (offsetLimit ?? 1)
                })`,
            }}
        >
            <Grid
                container
                ref={scrollRef}
                justify="center"
                alignContent="flex-start"
                className="App"
                style={{
                    minHeight: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                <Header mobile={true} />
                <Grid
                    item
                    xs={12}
                    style={{
                        minHeight: '100%',
                        marginBottom: '8rem',
                        position: 'relative',
                    }}
                >
                    <Typography
                        variant="h4"
                        style={{ fontWeight: 800, marginTop: '3rem' }}
                    >
                        Video, meet social.
                    </Typography>
                    <Typography
                        variant="h4"
                        style={{ fontWeight: 800, marginTop: '0.3rem' }}
                    >
                        You're going to love each other.
                    </Typography>
                    <Grid
                        item
                        xs={12}
                        container
                        justify="space-evenly"
                        style={{ height: '6vmax', marginTop: '2rem' }}
                    >
                        <Grid item xs={6} style={{ height: '6vmax' }}>
                            <a href="https://play.google.com/store/apps/details?id=com.snibblecorp.snibble">
                                <img
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        marginRight: '-2rem',
                                    }}
                                    alt="google play"
                                    src="assets/download_play_store.svg"
                                />
                            </a>
                        </Grid>
                        <Grid item xs={6} style={{ height: '6vmax' }}>
                            <a href="https://apps.apple.com/ca/app/snibble/id1464594506">
                                <img
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        marginLeft: '-2rem',
                                    }}
                                    alt="app store"
                                    src="assets/download_app_store.svg"
                                />
                            </a>
                        </Grid>
                    </Grid>
                    <div
                        style={{
                            minHeight: '100%',
                            position: 'sticky',
                            transform: 'scale(0.7)',
                            backgroundColor: 'transparent',
                        }}
                    >
                        <img
                            alt="hi"
                            src="assets/svgs/iPhoneBackground.svg"
                            style={{
                                zIndex: 1,
                                top: '25%',
                                left: '50%',
                                width: '100vmin',
                                position: 'absolute',
                                transform: 'translate(-50%,0%)',
                            }}
                        />
                        <img
                            alt="hi"
                            src="assets/MySnibble-Mockup.png"
                            style={{
                                zIndex: 2,
                                top: '25%',
                                left: '50%',
                                height: '100vmax',
                                position: 'absolute',
                                transform: 'translate(-50%,-25%)',
                            }}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} ref={sectionOneRef} className="">
                    <Grid
                        item
                        xs={12}
                        style={{
                            minHeight: '100%',
                            position: 'relative',
                            margin: '12vmax auto 2rem',
                        }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: '1.75rem',

                                fontWeight: 700,
                                color: `rgba(255, 255, 255, ${
                                    0 + scrollY / (offsetLimit ?? 1)
                                })`,
                                transform: `scale(${
                                    0 + scrollY / (offsetLimit ?? 1) > 1
                                        ? 1
                                        : 0 + scrollY / (offsetLimit ?? 1)
                                })`,
                                position: 'sticky',
                                marginBottom: '1.5rem',
                                top: 0,
                            }}
                        >
                            Snibble makes
                            <br /> best friends better.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    ref={explosionRef}
                    className="section-mobile"
                    style={{
                        position: 'relative',
                        opacity: (0 + scrollY / (offsetLimit ?? 1)) * 0.8,
                    }}
                >
                    <Grid item xs={12} sm={6}>
                        <img
                            style={{
                                margin: 'auto',
                                width: '90%',
                                transform: 'translateY(-50%)',
                                top: '50%',
                                position: 'relative',
                            }}
                            alt="hi"
                            src="assets/svgs/share.svg"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <img
                            style={{
                                margin: 'auto',
                                width: '90%',
                                transform: 'translateY(-50%)',
                                top: '50%',
                                position: 'relative',
                            }}
                            alt="hi"
                            src="assets/svgs/realtime.svg"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <img
                            style={{
                                margin: 'auto',
                                width: '90%',
                                transform: 'translateY(-50%)',
                                top: '50%',
                                position: 'relative',
                            }}
                            alt="hi"
                            src="assets/svgs/react.svg"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <img
                            style={{
                                margin: 'auto',
                                width: '90%',
                                transform: 'translateY(-50%)',
                                top: '50%',
                                position: 'relative',
                            }}
                            alt="hi"
                            src="assets/svgs/chat.svg"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <img
                            style={{
                                margin: 'auto',
                                width: '90%',
                                transform: 'translateY(-50%)',
                                top: '50%',
                                position: 'relative',
                            }}
                            alt="hi"
                            src="assets/svgs/local.svg"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <img
                            style={{
                                margin: 'auto',
                                width: '90%',
                                transform: 'translateY(-50%)',
                                top: '50%',
                                position: 'relative',
                            }}
                            alt="hi"
                            src="assets/svgs/reco.svg"
                        />
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={11}
                    container
                    justify="space-evenly"
                    className="section-mobile"
                    style={{
                        position: 'relative',
                        backgroundColor: '#1C1E22',
                        marginTop: '4rem',
                        marginBottom: '5rem',
                    }}
                >
                    <Grid item xs={10}>
                        <Typography
                            variant="h5"
                            style={{
                                color: 'white',
                                fontWeight: 700,
                                margin: '3rem 0',
                                fontSize: '1.25rem',
                                position: 'relative',
                            }}
                        >
                            With Snibble you’re not just watching videos. You’re
                            curating the world’s best videos together with your
                            friends. You’re reacting to what your friends
                            recommend. You’re sharing, chatting, and even
                            watching with your friends, all in real time.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        style={{
                            height: 'fit-content',
                            margin: '0.25rem 0.25rem 4rem',
                        }}
                    >
                        <img
                            style={{
                                height: '50%',
                            }}
                            src="assets/svgs/vpmockup-mobile.svg"
                        />
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    id="advertising"
                    ref={sectionTwoRef}
                    justify="space-evenly"
                    className="section-mobile"
                >
                    <Grid
                        container
                        justify="space-around"
                        item
                        xs={12}
                        style={{
                            color: 'black',
                            minHeight: '100%',
                        }}
                    >
                        <Grid item xs={10}>
                            <Typography
                                variant="h4"
                                style={{
                                    textAlign: 'left',
                                    fontWeight: 800,
                                    marginBottom: '2rem',
                                    fontSize: '1.75rem',
                                }}
                            >
                                Snibble makes your ads better.
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={10}
                            container
                            spacing={1}
                            justify="flex-start"
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 700,
                                        margin: '1rem 0',
                                        fontSize: '1.5rem',
                                    }}
                                >
                                    Quality
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: 'left',
                                        fontSize: '1.125rem',
                                        fontWeight: 600,
                                    }}
                                >
                                    Your ads are run in a quality, premium
                                    environment, which enhances your brand. This
                                    also has the added advantage of providing
                                    brand safety.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 700,
                                        margin: '1rem 0',
                                        fontSize: '1.5rem',
                                    }}
                                >
                                    Safety
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '1.125rem',

                                        textAlign: 'left',
                                    }}
                                >
                                    Being a closed social network between real
                                    friends only, there are no issues with
                                    bullying or hate speech that other platforms
                                    have.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 700,
                                        margin: '1rem 0',
                                        fontSize: '1.5rem',
                                    }}
                                >
                                    Positivity
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: 'left',
                                        fontSize: '1.125rem',
                                        fontWeight: 600,
                                    }}
                                >
                                    Your advertising runs in a fun, positive
                                    environment that is not overly heavy on
                                    advertising.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 700,
                                        margin: '1rem 0',
                                        fontSize: '1.5rem',
                                    }}
                                >
                                    Exclusivity
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: 'left',
                                        fontSize: '1.125rem',
                                        fontWeight: 600,
                                    }}
                                >
                                    Ads also run in a more exclusive environment
                                    and are more positively received because the
                                    audience is not constantly fighting off
                                    multiple pre-roll ads, mid-roll ads, ads
                                    that pop up from the bottom, overlay or
                                    across the top.
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 700,
                                        margin: '1rem 0',
                                        fontSize: '1.5rem',
                                    }}
                                >
                                    Social
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '1.125rem',
                                        textAlign: 'left',
                                    }}
                                >
                                    Snibble is designed to make everything
                                    shareable, even the ads. We help create
                                    network effects and conversations about
                                    advertising that no other platform has even
                                    considered.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    id="contentPartners"
                    justify="space-evenly"
                    className="section-mobile"
                >
                    <Grid item xs={10}>
                        <Typography
                            variant="h4"
                            style={{
                                textAlign: 'left',
                                margin: '3rem 0 2rem',
                                fontWeight: 800,
                                fontSize: '1.75rem',
                            }}
                        >
                            Your content is in exceptional company.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        spacing={2}
                        container
                        justify="space-evenly"
                    >
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-ign.png"
                                alt="IGN"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-euronews.png"
                                alt="EuroNews"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-verizon.png"
                                alt="Verizon"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-billboard.png"
                                alt="Billboard"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-mashable.png"
                                alt="Mashable"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-brut.png"
                                alt="brut"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-sportsgrid.png"
                                alt="SportsGrid"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-venntv.png"
                                alt="FailArmy"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-insighttv.png"
                                alt="PetCollective"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-vogue.png"
                                alt="Vogue"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-watchmojo.png"
                                alt="WatchMojo"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-vanityfair.png"
                                alt="Vanityfair"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-dailyfix.png"
                                alt="DailyFix"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-wired.png"
                                alt="Wired"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-arcadecloud.png"
                                alt="ArcadeCloud"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-bbc.png"
                                alt="BBC"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-cheddar.png"
                                alt="cheddar"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-wochit.png"
                                alt="wochit"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-huffpost.png"
                                alt="huffpost"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-genius.png"
                                alt="Genius"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-abc.png"
                                alt="ABC"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-etcanada.png"
                                alt="ET"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-gq.png"
                                alt="GQ"
                            />
                        </Grid>
                        <Grid item xs={6} style={{ margin: '0.5rem 0' }}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-intheknow.png"
                                alt="ITK"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    justify="space-evenly"
                    id="propaganda"
                    container
                >
                    <Grid item xs={10}>
                        <Typography
                            variant="h4"
                            style={{
                                display: 'flex',
                                textAlign: 'left',
                                fontWeight: 800,
                                alignContent: 'center',
                                margin: '4rem auto 1rem',
                            }}
                        >
                            Propaganda
                            <span style={{ flexGrow: 1 }} />
                            <IconButton
                                disabled={newsOffset >= 0}
                                style={{
                                    transition: 'all 0.2s ease-in-out 0s',
                                    opacity: newsOffset >= 0 ? 0.2 : 1,
                                    transform: 'translateY(-25%)',
                                }}
                                onClick={() =>
                                    setnewsOffset(newsOffset + CARD_WIDTH + 32)
                                }
                            >
                                <img height="48px" src="assets/svgs/back.svg" />
                            </IconButton>
                            <IconButton
                                disabled={
                                    newsOffset <=
                                    -(CARD_WIDTH + 32) * (articles.length - 1)
                                }
                                style={{
                                    transition: 'all 0.2s ease-in-out 0s',
                                    opacity:
                                        newsOffset <=
                                        -(CARD_WIDTH + 32) *
                                            (articles.length - 1)
                                            ? 0.2
                                            : 1,
                                    transform: 'translateY(-25%)',
                                }}
                                onClick={() =>
                                    setnewsOffset(
                                        newsOffset - (CARD_WIDTH + 32)
                                    )
                                }
                            >
                                <img
                                    height="48px"
                                    src="assets/svgs/forward.svg"
                                />
                            </IconButton>
                        </Typography>
                        <Grid
                            item
                            xs={12}
                            container
                            justify="flex-start"
                            style={{
                                margin: '0 auto',
                                flexWrap: 'nowrap',
                                transform: `translateX(${newsOffset}px)`,
                                transition: 'all 0.2s ease-in-out 0s',
                            }}
                        >
                            {articles.map((article) => (
                                <Grid
                                    key={article.link}
                                    item
                                    xs={12}
                                    style={{
                                        fontWeight: 600,
                                        flexShrink: 0,
                                        margin: '0 1rem',
                                        maxWidth: CARD_WIDTH,
                                        minWidth: CARD_WIDTH,
                                    }}
                                >
                                    <Card elevation={0}>
                                        <CardMedia
                                            component="img"
                                            src={article.thumbnail}
                                            style={{
                                                border: 'thin solid lightgrey',
                                                borderRadius: '4px',
                                                width: '99%',
                                                margin: '0 auto',
                                            }}
                                            title={article.title}
                                        />
                                        <CardContent
                                            style={{
                                                textAlign: 'left',
                                                paddingRight: 0,
                                                paddingLeft: 0,
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                                style={{
                                                    padding: 0,
                                                    fontWeight: 600,
                                                    fontSize: '0.75rem',
                                                }}
                                            >
                                                {article.provider}{' '}
                                                {new Date(
                                                    article.date
                                                ).toDateString()}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: '0.94rem',
                                                }}
                                            >
                                                {article.title}
                                            </Typography>
                                        </CardContent>
                                        <CardActions
                                            style={{
                                                padding: 0,
                                                marginTop: '-0.5rem',
                                            }}
                                        >
                                            <Button
                                                size="small"
                                                component="a"
                                                color="primary"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                    fontWeight: 600,
                                                    color: 'black',
                                                }}
                                            >
                                                — Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    id="contactUs"
                    justify="space-evenly"
                    className="section-mobile"
                    style={{ height: 'auto' }}
                >
                    <Grid
                        container
                        item
                        xs={12}
                        justify="space-evenly"
                        style={{
                            margin: 'auto',
                            padding: '2rem 0',
                            alignContent: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F7F7F7',
                        }}
                    >
                        <Grid item xs={10} style={{ alignContent: 'center' }}>
                            <Typography
                                variant="h4"
                                style={{
                                    textAlign: 'left',
                                    fontWeight: 800,
                                }}
                            >
                                Don't be shy
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{
                                    fontWeight: 600,
                                    textAlign: 'left',
                                    margin: '1rem 0',
                                }}
                            >
                                If you have any questions or concerns, please
                                send us an email and we’ll get back to you as
                                quickly as possible.
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                disabled={loading || sent !== null}
                                fullWidth
                                variant="outlined"
                                placeholder="Name"
                                onChange={(event) => {
                                    setName(event.target.value);
                                }}
                                style={{
                                    margin: '1rem 0',
                                    backgroundColor: 'white',
                                }}
                            />
                            <TextField
                                disabled={loading || sent !== null}
                                fullWidth
                                variant="outlined"
                                placeholder="Email"
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                                style={{
                                    margin: '1rem 0',
                                    backgroundColor: 'white',
                                }}
                            />
                            <TextField
                                disabled={loading || sent !== null}
                                rows={4}
                                fullWidth
                                multiline
                                value={message}
                                onChange={(event) => {
                                    setMessage(event.target.value);
                                }}
                                placeholder="Message"
                                variant="outlined"
                                style={{
                                    margin: '1rem 0',
                                    backgroundColor: 'white',
                                }}
                            />
                            <Recaptcha
                                render="explicit"
                                style={{
                                    margin: '1rem auto',
                                    width: 'fit-content',
                                }}
                                onloadCallback={() => console.log('rendered')}
                                verifyCallback={async (response: string) => {
                                    let result: any = await fetch(
                                        `https://warroom.snibblecorp.com/v1/verify`,
                                        {
                                            method: 'POST',
                                            headers: {
                                                Accept: 'application/json',
                                                'Content-Type':
                                                    'application/json',
                                            },
                                            body: JSON.stringify({
                                                capcha: response,
                                            }),
                                        }
                                    );

                                    result = await result.json();

                                    setValid(result.success);
                                }}
                                sitekey="6LcFzsEUAAAAAAgPYn8TdQVDVG53Pf858yOF4P9Y"
                            />
                            <Typography variant="caption">
                                This site is protected by reCAPTCHA and the
                                Google{' '}
                                <a href="https://policies.google.com/privacy?hl=en">
                                    Privacy Policy
                                </a>{' '}
                                and{' '}
                                <a href="https://policies.google.com/privacy?hl=en">
                                    Terms of Service
                                </a>{' '}
                                apply.
                            </Typography>
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                disabled={
                                    !name ||
                                    !valid ||
                                    !email ||
                                    loading ||
                                    !message ||
                                    sent !== null
                                }
                                style={{
                                    color: 'white',
                                    padding: '1rem',
                                    margin: '1rem 0',
                                    fontWeight: 'bold',
                                }}
                                onClick={handleSend}
                            >
                                {sent ? 'Sent!' : 'Send'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    style={{ height: '64px', marginTop: '2rem' }}
                >
                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                        &#169;{new Date().getFullYear()} Snibble Corp. All
                        rights reserved
                    </Typography>

                    <Typography
                        variant="body1"
                        style={{
                            fontWeight: 600,
                            marginTop: '1.5rem',
                            paddingBottom: '2rem',
                        }}
                    >
                        <a
                            href="assets/tos.pdf"
                            style={{
                                color: 'black',
                                margin: '0 0.5rem',
                                textDecoration: 'none',
                            }}
                        >
                            Terms of Service
                        </a>{' '}
                        |{' '}
                        <a
                            href="assets/privacy.pdf"
                            style={{
                                color: 'black',
                                margin: '0 0.5rem',
                                textDecoration: 'none',
                            }}
                        >
                            Privacy Policy
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Desktop;
