import '../App.css';
import Header from './header';
import Recaptcha from './recaptcha';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, withStyles } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { TextField as MTextField, Paper as MPaper } from '@material-ui/core';
import React, {
    useState,
    useEffect,
    useRef,
    SetStateAction,
    Dispatch,
} from 'react';

const TextField = withStyles({
    root: {
        '& fieldset': {
            border: 'none',
        },
    },
})(MTextField);

const Paper = withStyles({
    root: {
        width: '600px',
        height: '600px',
    },
})(MPaper);

function getTopPosition(scrollY: number, offsetLimit: number): number {
    let t = Math.abs(scrollY - offsetLimit - 50);

    t *= 0.8;

    if (t < 0.5) {
        t = 0.5;
    }

    return t;
}

function getStyle(
    start: { top: number; left: number },
    end: { top: number; left: number },
    scroll: number,
    scrollOffset: number,
    scrollLimit: number | null = null
): any {
    let top = start.top;
    let left = start.left;

    if (scroll && scrollOffset) {
        if (scroll > scrollOffset) {
            top = end.top;
            left = end.left;
        } else {
            let offsetPercent = scrollOffset / scroll;

            let topDifference = Math.abs(end.top - start.top);
            if (start.top < end.top) {
                top += topDifference / offsetPercent;
            } else {
                top -= topDifference / offsetPercent;
            }

            let leftDifference = Math.abs(end.left - start.left);
            if (start.left < end.left) {
                left += leftDifference / offsetPercent;
            } else {
                left -= leftDifference / offsetPercent;
            }
        }
    }

    return {
        width: '20%',
        top: `${top}%`,
        left: `${left}%`,
        position: 'absolute',
        transform: 'translate(-50%,-50%)',
    };
}

// const cardWidth = 300;

function Desktop({ articles }: { articles: Array<any> }) {
    const cardRef = useRef(null);
    const videoRef = useRef(null);
    const scrollRef = useRef(null);
    const videoDivRef = useRef(null);
    const explosionRef = useRef(null);
    const sectionOneRef = useRef(null);
    const sectionTwoRef = useRef(null);

    const [cardWidth, setCardWidth] = useState(0);
    const [name, setName]: [
        string,
        Dispatch<SetStateAction<string>>
    ] = useState('');
    const [email, setEmail]: [
        string,
        Dispatch<SetStateAction<string>>
    ] = useState('');
    const [message, setMessage]: [
        string,
        Dispatch<SetStateAction<string>>
    ] = useState('');

    const [offset, setOffset]: [
        number,
        Dispatch<SetStateAction<number>>
    ] = useState(0);

    const [scrollY, setScrollY]: [
        number,
        Dispatch<SetStateAction<number>>
    ] = useState(0);

    const [newsOffset, setnewsOffset]: [
        number,
        Dispatch<SetStateAction<number>>
    ] = useState(0);

    const [open, setOpen] = useState(false);
    const [valid, setValid] = useState(false);
    const [videoTop, setVideoTop] = useState(0);
    const [loading, setLoading] = useState(false);
    const [secondLimit, setSecondLimit] = useState(0);
    const [sent, setSent]: [string | null, any] = useState(null);
    const [offsetLimit, setOffsetLimit]: [number | null, any] = useState(null);

    useEffect(() => {
        (videoRef?.current as any)?.play();

        (scrollRef?.current as any).addEventListener('scroll', () => {
            setScrollY((scrollRef?.current as any).scrollTop);

            if (!cardWidth && articles) {
                setCardWidth(
                    (cardRef.current as any)?.getBoundingClientRect().width
                );
            }
        });

        setOffset((explosionRef?.current as any)?.getBoundingClientRect().top);

        setOffsetLimit(
            (videoDivRef?.current as any)?.getBoundingClientRect().bottom
        );
        setSecondLimit(
            (sectionTwoRef?.current as any)?.getBoundingClientRect().top - 200
        );
        setVideoTop((videoDivRef?.current as any)?.getBoundingClientRect().top);
    }, []);

    async function handleSend() {
        try {
            if (sent) {
                return;
            }

            setLoading(true);

            await fetch(
                'https://ipwmovx2ze.execute-api.us-east-1.amazonaws.com/default/ContactForm',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ name, email, content: message }),
                }
            );

            setSent('Success');
            setLoading(false);
            setOpen(true);
        } catch (e) {
            setSent('error');
            setLoading(false);
            setOpen(true);
        }
    }

    return (
        <Grid
            container
            justify="center"
            style={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                // transition: 'all 0.1s ease-in-out 0s',
                backgroundColor: `rgba(255, 255, 255, ${
                    1 - scrollY / (offsetLimit ?? 1) < 0 &&
                    scrollY > secondLimit
                        ? (sectionTwoRef?.current as any)?.getBoundingClientRect()
                              .top > 0
                            ? (
                                  (Math.abs(
                                      1 -
                                          scrollY /
                                              ((sectionTwoRef?.current as any)?.getBoundingClientRect()
                                                  .top ?? 1)
                                  ) /
                                      100) *
                                  1.25
                              ).toFixed(2)
                            : 1
                        : 1 - scrollY / (offsetLimit ?? 1)
                })`,
            }}
        >
            <Dialog
                onClose={() => {
                    setOpen(false);
                }}
                open={open}
                className="animate__animated animate__fadeIn"
            >
                <IconButton
                    onClick={() => {
                        setOpen(false);
                    }}
                    style={{
                        left: '100%',
                        width: 'fit-content',
                        position: 'relative',
                        transform: 'translateX(-100%)',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle style={{ textAlign: 'center', marginTop: '4rem' }}>
                    <img
                        src={
                            sent === 'success'
                                ? 'assets/svgs/SuccessIcon.svg'
                                : 'assets/svgs/ErrorIcon.svg'
                        }
                    />
                </DialogTitle>
                <DialogContent>
                    <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                            textAlign: 'center',
                            margin: 'auto 4rem',
                            fontWeight: 700,
                        }}
                    >
                        {sent === 'success'
                            ? 'Message Sent!'
                            : 'There was a problem.'}
                    </Typography>
                    <Typography
                        variant="h5"
                        gutterBottom
                        color="textSecondary"
                        style={{ textAlign: 'center', margin: 'auto 4rem' }}
                    >
                        {sent === 'success'
                            ? 'Thank you for your message. We’ll get back to you as quickly as possible.'
                            : 'Please try again.'}
                    </Typography>
                </DialogContent>
                <DialogActions
                    style={{ justifyContent: 'center', margin: '4rem 0 2rem' }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid
                container
                ref={scrollRef}
                className="App"
                justify="center"
                alignContent="flex-start"
                style={{
                    margin: '0 auto',
                    minHeight: '100%',
                    overflowX: 'hidden',
                    overflowY: 'visible',
                }}
            >
                <Header mobile={false} />

                {((window.visualViewport
                    ? window.visualViewport.width >= 1440
                    : (document
                          ?.getElementById('dummyDivForScreenSize')
                          ?.getBoundingClientRect().left ||
                          window.innerWidth) >= 1440) && (
                    <Grid
                        item
                        id="playingNow"
                        xs={12}
                        container
                        ref={videoDivRef}
                        style={{
                            width: '100vw',
                            display: 'block',
                            color: '#18191F',
                            height: '150vmax',
                            marginTop: '-64px',
                            position: 'relative',
                            pointerEvents: 'none',
                            overflow: videoTop < scrollY ? '' : 'hidden',
                        }}
                    >
                        <div
                            style={{
                                top: videoTop < scrollY ? '50%' : '1%',
                                width: '100vw',
                                height: 'fit-content',
                                position:
                                    videoTop < scrollY ? 'sticky' : 'absolute',
                                transform: `${
                                    videoTop < scrollY ? 'translateY(-50%)' : ''
                                } scale(${
                                    videoTop < scrollY
                                        ? 1.25 -
                                              Math.abs(
                                                  (scrollY /
                                                      ((videoDivRef?.current as any)?.getBoundingClientRect()
                                                          .bottom ?? 0)) *
                                                      1.2
                                              ) >
                                          0.5
                                            ? 1.25 -
                                              Math.abs(
                                                  (scrollY /
                                                      ((videoDivRef?.current as any)?.getBoundingClientRect()
                                                          .bottom ?? 0)) *
                                                      1.2
                                              )
                                            : 0.5
                                        : 1.25
                                })`,
                            }}
                        >
                            <div
                                style={{
                                    top: 0,
                                    width: '100vw',
                                    height: '100vh',
                                    position: 'relative',
                                    display: 'inline-block',
                                }}
                            >
                                <div
                                    style={{
                                        zIndex: 1,
                                        top: '20vh',
                                        left: '50%',
                                        width: '100%',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                    className={`animate__animated animate__${
                                        videoTop < scrollY
                                            ? 'fadeOut'
                                            : 'fadeIn'
                                    }`}
                                >
                                    <Typography
                                        variant="h2"
                                        style={{
                                            color: 'white',
                                            fontWeight: 800,
                                            marginTop: '4rem',
                                            fontSize: '2.75rem',
                                        }}
                                    >
                                        Video, meet social.
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        style={{
                                            color: 'white',
                                            fontWeight: 800,
                                            marginTop: '1rem',
                                            fontSize: '2.75rem',
                                        }}
                                    >
                                        You're going to love each other.
                                    </Typography>
                                </div>
                                <video
                                    ref={videoRef}
                                    style={{
                                        top: '50%',
                                        left: '50%',
                                        zIndex: 0,
                                        width: '95%',
                                        height: '95%',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                    loop
                                    muted
                                >
                                    <source src="assets/RealtimePreviewCompressed.mp4" />
                                </video>
                                <img
                                    style={{
                                        top: 0,
                                        left: 0,
                                        zIndex: 1,
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                    }}
                                    src="assets/svgs/nowPlaying.svg"
                                />
                                {2 -
                                    Math.abs(
                                        (scrollY /
                                            ((videoDivRef?.current as any)?.getBoundingClientRect()
                                                .bottom ?? 0)) *
                                            3
                                    ) <
                                    1 && (
                                    <Typography
                                        className="animate__animated animate__fadeInLeft"
                                        variant="subtitle2"
                                        style={{
                                            left: '5.5rem',
                                            fontWeight: 700,
                                            bottom: '-3rem',
                                            marginTop: '2.75rem',
                                            fontSize: '2.25rem',
                                            position: 'absolute',
                                        }}
                                    >
                                        Video courtesy of GoPro
                                    </Typography>
                                )}
                            </div>
                        </div>
                    </Grid>
                )) || (
                    <Grid
                        item
                        id="playingNow"
                        xs={12}
                        container
                        ref={videoDivRef}
                        style={{
                            color: '#18191F',
                            height: '100vmin',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                zIndex: 1,
                                top: '10vh',
                                left: '50%',
                                width: '100%',
                                position: 'absolute',
                                transform: 'translate(-50%, -50%)',
                            }}
                            className="animate__animated animate__fadeIn"
                        >
                            <Typography
                                variant="h2"
                                style={{
                                    fontWeight: 800,
                                    marginTop: '4rem',
                                    fontSize: '4.75rem',
                                }}
                            >
                                Video, meet social.
                            </Typography>
                            <Typography
                                variant="h2"
                                style={{
                                    fontWeight: 800,
                                    marginTop: '1rem',
                                    fontSize: '4.75rem',
                                }}
                            >
                                You're going to love each other.
                            </Typography>
                        </div>
                        <div
                            style={{
                                top: '50%',
                                left: '50%',
                                width: '95%',
                                height: '50vmin',
                                position: 'absolute',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <div
                                style={{
                                    top: 0,
                                    width: '100%',
                                    height: '100%',
                                    position: 'relative',
                                    display: 'inline-block',
                                }}
                            >
                                <video
                                    ref={videoRef}
                                    style={{
                                        top: '50%',
                                        left: '50%',
                                        zIndex: 0,
                                        width: '90vmin',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                    loop
                                    muted
                                >
                                    <source src="assets/RealtimePreviewCompressed.mp4" />
                                </video>
                                <img
                                    style={{
                                        top: '50%',
                                        left: '50%',
                                        zIndex: 1,
                                        width: '95vmin',
                                        position: 'absolute',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                    src="assets/svgs/nowPlaying.svg"
                                />
                                <Typography
                                    className="animate__animated animate__fadeInLeft"
                                    variant="subtitle2"
                                    style={{
                                        bottom: '-2vmin',
                                        left: '15vw',
                                        fontWeight: 700,
                                        position: 'absolute',
                                    }}
                                >
                                    Video courtesy of GoPro
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                )}

                <Grid item xs={12} ref={sectionOneRef} className="sections">
                    <Grid
                        item
                        xs={12}
                        style={{
                            minHeight: '100%',
                            maxWidth: '1440px',
                            margin: '0 auto',
                            position: 'relative',
                        }}
                    >
                        <Typography
                            variant="h3"
                            style={{
                                fontWeight: 700,
                                fontSize: '2.3rem',
                                color: `rgba(255, 255, 255, ${
                                    (0 + scrollY / (offsetLimit ?? 1)) * 0.8
                                })`,
                                transform: `scale(${
                                    (0 + scrollY / (offsetLimit ?? 1)) * 0.8 > 1
                                        ? 1
                                        : (0 + scrollY / (offsetLimit ?? 1)) *
                                          0.8
                                })`,
                                position: 'sticky',
                                top: 0,
                            }}
                        >
                            Snibble makes best friends better.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    ref={explosionRef}
                    className="section explosion"
                    style={{
                        position: 'relative',
                        minHeight: 0,
                    }}
                >
                    <img
                        alt="hi"
                        src="assets/svgs/share.svg"
                        style={getStyle(
                            { top: 50, left: 50 },
                            { top: 20, left: 82 },
                            scrollY,
                            offset,
                            offsetLimit
                        )}
                    />

                    <img
                        alt="hi"
                        src="assets/svgs/reco.svg"
                        style={getStyle(
                            { top: 50, left: 50 },
                            { top: 20, left: 18 },
                            scrollY,
                            offset,
                            offsetLimit
                        )}
                    />

                    <img
                        alt="hi"
                        src="assets/svgs/realtime.svg"
                        style={getStyle(
                            { top: 50, left: 50 },
                            { top: 50, left: 12 },
                            scrollY,
                            offset,
                            offsetLimit
                        )}
                    />

                    <img
                        alt="hi"
                        src="assets/svgs/local.svg"
                        style={getStyle(
                            { top: 50, left: 50 },
                            { top: 50, left: 88 },
                            scrollY,
                            offset,
                            offsetLimit
                        )}
                    />

                    <img
                        alt="hi"
                        src="assets/svgs/react.svg"
                        style={getStyle(
                            { top: 50, left: 50 },
                            { top: 80, left: 18 },
                            scrollY,
                            offset,
                            offsetLimit
                        )}
                    />

                    <img
                        alt="hi"
                        src="assets/svgs/chat.svg"
                        style={{
                            ...getStyle(
                                { top: 50, left: 50 },
                                { top: 80, left: 82 },
                                scrollY,
                                offset,
                                offsetLimit
                            ),
                        }}
                    />

                    <div
                        className={`animate__animated ${
                            scrollY >
                            (videoDivRef?.current as any)?.getBoundingClientRect()
                                .bottom +
                                250
                                ? 'animate__slideInUp'
                                : 'animate__slideOutDown'
                        }`}
                        style={{
                            left: '50%',
                            minHeight: '100%',
                            pointerEvents: 'none',
                            backgroundColor: 'transparent',
                            top:
                                scrollY >= (offsetLimit ?? 0) - 500 ? 0 : '50%',
                            position:
                                scrollY >= (offsetLimit ?? 0) - 500
                                    ? 'absolute'
                                    : 'fixed',
                        }}
                    >
                        <img
                            alt="hi"
                            src="assets/svgs/iPhoneBackground.svg"
                            style={{
                                width: '40vmax',
                                zIndex: 1,
                                top: '50%',
                                left: '50%',
                                opacity: `${
                                    0 + (scrollY / (offsetLimit ?? 1)) * 1.2 >
                                    0.5
                                        ? 0 +
                                          (scrollY / (offsetLimit ?? 1)) * 1.2
                                        : 0.5
                                }`,
                                position: 'absolute',
                                transform: 'translate(-50%,-50%)',
                            }}
                        />
                        <img
                            alt="hi"
                            src="assets/MySnibble-Mockup-min.png"
                            style={{
                                width: '25vmax',
                                zIndex: 2,
                                top: '50%',
                                left: '50%',
                                position: 'absolute',
                                transform: 'translate(-50%,-45%)',
                            }}
                        />
                    </div>
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    className="section"
                    justify="space-around"
                    style={{
                        minHeight: 0,
                        position: 'relative',
                        height: 'fit-content',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        className="mockup"
                        style={{
                            minHeight: '600px',
                            position: 'relative',
                            backgroundSize: '100% auto',
                            backgroundRepeat: 'no-repeat',
                            padding: '8vmin 8vmin 0 8vmin',
                            backgroundPosition: 'center top',
                            backgroundImage:
                                "url('assets/svgs/vpmockup-desktop.svg')",
                        }}
                    >
                        <Typography
                            variant="h4"
                            style={{
                                color: 'white',
                                fontWeight: 700,
                                width: '44vw',
                                textAlign: 'left',
                                fontSize: '2.25rem',
                            }}
                        >
                            With Snibble you’re not just watching videos. You’re
                            curating the world’s best videos together with your
                            friends. You’re reacting to what your friends
                            recommend. You’re sharing, chatting, and even
                            watching with your friends, all in real time.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    id="advertising"
                    ref={sectionTwoRef}
                    className="section"
                    justify="space-evenly"
                    style={{ minHeight: 0, height: 'fit-content' }}
                >
                    <Grid
                        container
                        justify="flex-start"
                        item
                        xs={12}
                        style={{
                            marginTop: '2rem',

                            color:
                                (sectionTwoRef?.current as any)?.getBoundingClientRect()
                                    .top > 0
                                    ? `rgb(${
                                          255 -
                                          (scrollY /
                                              ((sectionTwoRef?.current as any)?.getBoundingClientRect()
                                                  .top + 800 ?? 255)) *
                                              45
                                      },${
                                          255 -
                                          (scrollY /
                                              ((sectionTwoRef?.current as any)?.getBoundingClientRect()
                                                  .top + 800 ?? 255)) *
                                              45
                                      },${
                                          255 -
                                          (scrollY /
                                              ((sectionTwoRef?.current as any)?.getBoundingClientRect()
                                                  .top + 800 ?? 255)) *
                                              45
                                      }`
                                    : 'black',
                            minHeight: 0,
                            height: 'fit-content',
                        }}
                    >
                        <Grid
                            item
                            xs={8}
                            lg={5}
                            style={{
                                paddingBottom: '4rem',
                                height: 'fit-content',
                                minHeight: 0,
                            }}
                        >
                            <Typography
                                variant="h2"
                                style={{
                                    fontWeight: 800,
                                    textAlign: 'left',
                                }}
                            >
                                Snibble makes your ads better.
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            container
                            spacing={3}
                            justify="flex-start"
                        >
                            <Grid
                                item
                                xs={6}
                                md={4}
                                style={{
                                    height: 'fit-content',
                                    minHeight: 0,

                                    paddingBottom: '2rem',
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 700,
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Quality
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 600,
                                    }}
                                >
                                    Your ads are run in a quality, premium
                                    environment, which enhances your brand. This
                                    also has the added advantage of providing
                                    brand safety.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={4}
                                style={{
                                    height: 'fit-content',
                                    minHeight: 0,
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 700,
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Safety
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 600,
                                    }}
                                >
                                    Being a closed social network between real
                                    friends only, there are no issues with
                                    bullying or hate speech that other platforms
                                    have.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={4}
                                style={{
                                    height: 'fit-content',
                                    minHeight: 0,
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 700,
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Positivity
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 600,
                                    }}
                                >
                                    Your advertising runs in a fun, positive
                                    environment that is not overly heavy on
                                    advertising.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={4}
                                style={{
                                    height: 'fit-content',
                                    minHeight: 0,
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 700,
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Exclusivity
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 600,
                                    }}
                                >
                                    Ads also run in a more exclusive environment
                                    and are more positively received because the
                                    audience is not constantly fighting off
                                    multiple pre-roll ads, mid-roll ads, ads
                                    that pop up from the bottom, overlay or
                                    across the top.
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={6}
                                md={4}
                                style={{
                                    height: 'fit-content',
                                    minHeight: 0,
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 700,
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Social
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: 'left',
                                        fontWeight: 600,
                                    }}
                                >
                                    Snibble is designed to make everything
                                    shareable, even the ads. We help create
                                    network effects and conversations about
                                    advertising that no other platform has even
                                    considered.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    id="contentPartners"
                    item
                    xs={12}
                    container
                    style={{
                        minHeight: 0,
                        marginBottom: '6rem',
                        height: 'fit-content',
                        alignContent: 'flex-start',
                    }}
                    className="section"
                    justify="flex-start"
                >
                    <Grid item xs={10} md={7} style={{ height: 'fit-content' }}>
                        <Typography
                            variant="h2"
                            style={{
                                fontWeight: 800,
                                textAlign: 'left',
                                marginBottom: '3rem',
                            }}
                        >
                            Your content is in exceptional company.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        justify="space-evenly"
                        spacing={3}
                    >
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-ign.png"
                                alt="IGN"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-euronews.png"
                                alt="EuroNews"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-verizon.png"
                                alt="Verizon"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-billboard.png"
                                alt="Billboard"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-mashable.png"
                                alt="Mashable"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-brut.png"
                                alt="brut"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-sportsgrid.png"
                                alt="SportsGrid"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-venntv.png"
                                alt="FailArmy"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-insighttv.png"
                                alt="PetCollective"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-vogue.png"
                                alt="Vogue"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-watchmojo.png"
                                alt="WatchMojo"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-vanityfair.png"
                                alt="Vanityfair"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-dailyfix.png"
                                alt="DailyFix"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-wired.png"
                                alt="Wired"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-arcadecloud.png"
                                alt="ArcadeCloud"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-bbc.png"
                                alt="BBC"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-cheddar.png"
                                alt="cheddar"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-wochit.png"
                                alt="wochit"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-huffpost.png"
                                alt="huffpost"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-genius.png"
                                alt="Genius"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-abc.png"
                                alt="ABC"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-etcanada.png"
                                alt="ET"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-gq.png"
                                alt="GQ"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <img
                                style={{
                                    width: '100%',
                                }}
                                src="assets/categories/partner-intheknow.png"
                                alt="ITK"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    id="propaganda"
                    className="section"
                    justify="space-evenly"
                    style={{ minHeight: 0 }}
                >
                    <Grid item xs={12} style={{ height: '30%' }}>
                        <Typography
                            variant="h2"
                            style={{
                                display: 'flex',
                                textAlign: 'left',
                                fontWeight: 800,
                                alignContent: 'center',
                            }}
                        >
                            <span
                                style={{
                                    margin: 'auto 0',
                                }}
                            >
                                Propaganda
                            </span>
                            <span style={{ flexGrow: 1 }} />
                            <IconButton
                                disabled={
                                    newsOffset >
                                    -cardWidth * (articles.length - 2)
                                }
                                style={{
                                    opacity:
                                        newsOffset <=
                                        -cardWidth * (articles.length - 2)
                                            ? 1
                                            : 0,
                                }}
                                onClick={() => setnewsOffset(0)}
                            >
                                <img
                                    style={{ width: '50%' }}
                                    src="assets/svgs/back.svg"
                                />
                            </IconButton>
                            <IconButton
                                disabled={newsOffset >= 0}
                                style={{
                                    opacity: newsOffset >= 0 ? 0.2 : 1,
                                }}
                                onClick={() =>
                                    setnewsOffset(newsOffset + cardWidth)
                                }
                            >
                                <img src="assets/svgs/back.svg" />
                            </IconButton>
                            <IconButton
                                disabled={
                                    newsOffset <=
                                    -cardWidth * (articles.length - 2)
                                }
                                style={{
                                    opacity:
                                        newsOffset <=
                                        -cardWidth * (articles.length - 2)
                                            ? 0.2
                                            : 1,
                                }}
                                onClick={() =>
                                    setnewsOffset(newsOffset - cardWidth)
                                }
                            >
                                <img src="assets/svgs/forward.svg" />
                            </IconButton>
                            <IconButton
                                disabled={newsOffset !== 0}
                                style={{
                                    opacity: newsOffset === 0 ? 1 : 0,
                                }}
                                onClick={() =>
                                    setnewsOffset(
                                        -cardWidth * (articles.length - 2)
                                    )
                                }
                            >
                                <img
                                    style={{ width: '50%' }}
                                    src="assets/svgs/forward.svg"
                                />
                            </IconButton>
                        </Typography>
                        <Grid
                            item
                            xs={12}
                            container
                            justify="flex-start"
                            style={{
                                margin: '2rem auto 0',
                                flexWrap: 'nowrap',
                                transition: 'all 0.2s ease 0s',
                                transform: `translateX(${newsOffset}px)`,
                            }}
                        >
                            {articles.map((article, index) => (
                                <Grid
                                    item
                                    xs={4}
                                    ref={cardRef}
                                    key={article.link}
                                    style={{
                                        flexShrink: 0,
                                        fontWeight: 600,
                                        margin:
                                            index === 0
                                                ? '0 1rem 0 0'
                                                : '0 1rem',
                                    }}
                                >
                                    <Card elevation={0}>
                                        <CardMedia
                                            component="img"
                                            src={article.thumbnail}
                                            style={{
                                                border: 'thin solid lightgrey',
                                                borderRadius: '4px',
                                                width: '99%',
                                                margin: '0 auto',
                                            }}
                                            title={article.title}
                                        />
                                        <CardContent
                                            style={{
                                                textAlign: 'left',
                                                paddingRight: 0,
                                                paddingLeft: 0,
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                component="p"
                                                style={{
                                                    fontWeight: 600,

                                                    fontSize: '0.95rem',
                                                }}
                                            >
                                                {article.provider}{' '}
                                                {new Date(
                                                    article.date
                                                ).toDateString()}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                style={{
                                                    fontWeight: 600,
                                                    padding: 0,
                                                    fontSize: '1.075rem',
                                                }}
                                            >
                                                {article.title}
                                            </Typography>
                                        </CardContent>
                                        <CardActions style={{ padding: 0 }}>
                                            <Button
                                                size="small"
                                                component="a"
                                                color="primary"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={article.link}
                                                style={{
                                                    color: '#18191F',
                                                    fontWeight: 600,
                                                    fontSize: '1.075rem',
                                                }}
                                            >
                                                — Read more
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    id="contactUs"
                    className="section"
                    justify="space-evenly"
                    style={{ height: 'auto' }}
                >
                    <Grid
                        item
                        xs={12}
                        container
                        style={{
                            minHeight: 0,
                            alignContent: 'center',
                            justifyContent: 'space-around',
                            backgroundColor: '#F7F7F7',
                        }}
                    >
                        <Grid
                            item
                            xs={10}
                            md={4}
                            style={{ alignContent: 'center' }}
                        >
                            <Typography
                                variant="h2"
                                style={{
                                    fontWeight: 800,
                                    textAlign: 'left',
                                }}
                            >
                                Don't be shy
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{
                                    maxWidth: '30vmin',
                                    textAlign: 'left',
                                    fontWeight: 600,
                                    margin: '1.5rem 0',
                                }}
                            >
                                If you have any questions or concerns, please
                                send us an email and we’ll get back to you as
                                quickly as possible.
                            </Typography>
                        </Grid>
                        <Grid item xs={10} md={5}>
                            <TextField
                                fullWidth
                                disabled={loading || sent !== null}
                                variant="outlined"
                                placeholder="Name"
                                onChange={(event) => {
                                    setName(event.target.value);
                                }}
                                style={{
                                    margin: '0.75rem',
                                    backgroundColor: 'white',
                                }}
                            />
                            <TextField
                                fullWidth
                                disabled={loading || sent !== null}
                                variant="outlined"
                                placeholder="Email"
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                                style={{
                                    margin: '0.75rem',
                                    backgroundColor: 'white',
                                }}
                            />
                            <TextField
                                rows={4}
                                fullWidth
                                multiline
                                disabled={loading || sent !== null}
                                value={message}
                                onChange={(event) => {
                                    setMessage(event.target.value);
                                }}
                                placeholder="Message"
                                variant="outlined"
                                style={{
                                    margin: '0.75rem',
                                    marginBottom: '2rem',
                                    backgroundColor: 'white',
                                }}
                            />

                            <Recaptcha
                                render="explicit"
                                style={{
                                    margin: '1rem auto',
                                    width: 'fit-content',
                                }}
                                onloadCallback={() => console.log('rendered')}
                                verifyCallback={async (response: string) => {
                                    let result: any = await fetch(
                                        `https://warroom.snibblecorp.com/v1/verify`,
                                        {
                                            method: 'POST',
                                            headers: {
                                                Accept: 'application/json',
                                                'Content-Type':
                                                    'application/json',
                                            },
                                            body: JSON.stringify({
                                                capcha: response,
                                            }),
                                        }
                                    );

                                    result = await result.json();

                                    setValid(result.success);
                                }}
                                sitekey="6LcFzsEUAAAAAAgPYn8TdQVDVG53Pf858yOF4P9Y"
                            />
                            <Typography
                                variant="caption"
                                style={{
                                    margin: '0.75rem',
                                    marginBottom: '2rem',
                                }}
                            >
                                This site is protected by reCAPTCHA and the
                                Google{' '}
                                <a href="https://policies.google.com/privacy?hl=en">
                                    Privacy Policy
                                </a>{' '}
                                and{' '}
                                <a href="https://policies.google.com/privacy?hl=en">
                                    Terms of Service
                                </a>{' '}
                                apply.
                            </Typography>
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                disabled={
                                    !name ||
                                    !valid ||
                                    !email ||
                                    loading ||
                                    !message ||
                                    sent !== null
                                }
                                style={{
                                    color: 'white',
                                    margin: '1rem',
                                    padding: '1rem',
                                    marginTop: '2rem',
                                    fontWeight: 'bold',
                                }}
                                onClick={handleSend}
                            >
                                {sent ? 'Sent' : 'Send'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11} style={{ height: '64px' }}>
                    <Typography
                        variant="body1"
                        style={{ display: 'flex', fontWeight: 600 }}
                    >
                        &#169;{new Date().getFullYear()} Snibble Corp. All
                        rights reserved <span style={{ flexGrow: 1 }} />
                        <a
                            href="assets/tos.pdf"
                            style={{
                                color: '#18191F',
                                margin: '0 0.5rem',
                                textDecoration: 'none',
                            }}
                        >
                            Terms of Service
                        </a>{' '}
                        |{' '}
                        <a
                            href="assets/privacy.pdf"
                            style={{
                                color: '#18191F',
                                margin: '0 0.5rem',
                                textDecoration: 'none',
                            }}
                        >
                            Privacy Policy
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Desktop;
